import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import Modal from './Modal';

const Header = ({ announcementVisible }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [scrollingUp, setScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [catalogModalOpen, setCatalogModalOpen] = useState(false);
  const currentHost = window.location.host.replace(/^www\./, '');
  const navigate = useNavigate();

  const toggleNav = () => setNavOpen(!navOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);

      if (window.scrollY > lastScrollY) {
        setScrollingUp(false);
      } else {
        setScrollingUp(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  useEffect(() => {
    if (navOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [navOpen]);

  const handleLinkClick = (e) => {
    if (e.target.closest('a') && e.target.closest('a').getAttribute('href') === '/#series') {
      e.preventDefault();
      navigate('/');
      setTimeout(() => {
        document.querySelector('#series').scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
    setNavOpen(false);
  };

  const handleCatalogClick = (e) => {
    e.preventDefault();
    setCatalogModalOpen(true);
  };

  const headerClassName = `
    ${styles.header}
    ${(scrollingUp || isAtTop) ? styles.visible : styles.hidden}
    ${isAtTop ? styles.atTop : ''}
    ${navOpen ? styles.navOpen : ''}
    ${announcementVisible ? styles.announcementSpacing : ''}
  `.trim();

  return (
    <>
      <header className={headerClassName}>
        <div className={styles.headerContent}>
          <Link to="/" className={styles.logoLink}>
            <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" className={styles.logo} />
          </Link>

          <div className={`${styles.hamburger} ${navOpen ? styles.open : ''}`} onClick={toggleNav}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <nav className={`${styles.nav} ${navOpen ? styles.navOpen : ''}`}>
            <ul className={styles.navList}>
              <li>
                <NavLink
                  exact
                  to="/"
                  className={({ isActive }) => `${styles.navLink} ${isActive ? styles.activeLink : ''}`}
                  onClick={handleLinkClick}
                >
                  Home
                </NavLink>
              </li>
              <li className={styles.dropdown}>
                <NavLink
                  to="/#series"
                  className={styles.navLink}
                  onClick={handleLinkClick}
                >
                  Products
                </NavLink>
                <ul className={styles.dropdownContent}>
                  <li>
                    <NavLink
                      to="/architectural"
                      className={({ isActive }) => `${styles.navLink} ${isActive ? styles.activeLink : ''}`}
                      onClick={handleLinkClick}
                    >
                      Architectural Series
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`https://bgrs.${currentHost}`}
                      className={({ isActive }) => `${styles.navLink} ${isActive ? styles.activeLink : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleLinkClick}
                    >
                      Builder Series <span className={styles.externalIcon}>↗</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/commercial"
                      className={({ isActive }) => `${styles.navLink} ${isActive ? styles.activeLink : ''}`}
                      onClick={handleLinkClick}
                    >
                      Commercial Series
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) => `${styles.navLink} ${isActive ? styles.activeLink : ''}`}
                  onClick={handleLinkClick}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className={({ isActive }) => `${styles.navLink} ${isActive ? styles.activeLink : ''}`}
                  onClick={handleLinkClick}
                >
                  Contact
                </NavLink>
              </li>
              <li>
                <button
                  className={`${styles.navLink} ${styles.catalogButton}`}
                  onClick={handleCatalogClick}
                >
                  Catalogs
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {catalogModalOpen && (
        <Modal onClose={() => setCatalogModalOpen(false)}>
          <div className={styles.catalogOptions}>
            <h2>Select a Catalog</h2>
            <div className={styles.catalogGrid}>
              {[
                { title: 'Complete Product Line Catalog', file: 'ABC Railing - Product Catalog.pdf' },
                { title: 'Architectural Series', file: 'ABC Railing - Architectural Catalog.pdf' },
                { title: 'Builder Series', file: 'ABC Railing - Builder Catalog.pdf' },
                { title: 'Commercial Series', file: 'ABC Railing - Commercial Catalog.pdf' },
              ].map((catalog) => (
                <a
                  key={catalog.title}
                  href={`/${catalog.file}`}
                  className={styles.catalogCard}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{catalog.title}</span>
                </a>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Header;
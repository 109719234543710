import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './Privacy.module.css';

const Privacy = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Privacy Policy - ABC Railing</title>
        <meta name="description" content="Learn about our privacy policy and how we protect your information." />
      </Helmet>
      <h1 className={styles.heading}>Privacy Policy</h1>

      <section aria-labelledby="introduction">
        <h2 id="introduction" className={styles.subheading}>Introduction</h2>
        <p>
          At ABC Railing, we value your privacy and are committed to protecting your personal information.
          This Privacy Policy outlines how we collect, use, and share your information.
        </p>
      </section>

      <section aria-labelledby="info-we-collect">
        <h2 id="info-we-collect" className={styles.subheading}>Information We Collect</h2>
        <p>We may collect personal information from you when you:</p>
        <ul className={styles.bulletPoints}>
          <li>Visit our website</li>
          <li>Subscribe to our newsletter</li>
          <li>Contact us via email or phone</li>
          <li>Make a purchase or inquiry</li>
        </ul>
      </section>

      <section aria-labelledby="how-we-use-info">
        <h2 id="how-we-use-info" className={styles.subheading}>How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <ul className={styles.bulletPoints}>
          <li>To provide and improve our services</li>
          <li>To communicate with you</li>
          <li>To process your transactions</li>
          <li>To send you promotional materials (if you opted in)</li>
        </ul>
      </section>

      <section aria-labelledby="sharing-info">
        <h2 id="sharing-info" className={styles.subheading}>Sharing Your Information</h2>
        <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
        <ul className={styles.bulletPoints}>
          <li>Service providers who assist us in our business operations</li>
          <li>Law enforcement or legal authorities if required by law</li>
        </ul>
      </section>

      <section aria-labelledby="your-rights">
        <h2 id="your-rights" className={styles.subheading}>Your Rights</h2>
        <p>You have the right to:</p>
        <ul className={styles.bulletPoints}>
          <li>Access the personal information we hold about you</li>
          <li>Request correction of any inaccurate information</li>
          <li>Request deletion of your personal information</li>
        </ul>
      </section>

      <section aria-labelledby="policy-changes">
        <h2 id="policy-changes" className={styles.subheading}>Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page.</p>
      </section>

      <section aria-labelledby="contact-us">
        <h2 id="contact-us" className={styles.subheading}>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@abcrailing.com">hello@abcrailing.com</a>.</p>
      </section>
    </div>
  );
};

export default Privacy;
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './Contact.module.css';

function Contact() {
  return (
    <div className={styles.contactContainer}>
      <Helmet>
        <title>Contact Us - ABC Railing</title>
        <meta name="description" content="Contact ABC Railing for more information about our products and services." />
      </Helmet>
      <div className={styles.contentContainer}>
        <div className={styles.mapContainer} aria-label="Google Map">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.4344944538574!2d-113.573120184424!3d53.56383078002978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0229bde4a6439%3A0xe7f7bff0249624d!2s15810%20114%20Ave%20NW%2C%20Edmonton%2C%20AB%20T5M%202Z4%2C%20Canada!5e0!3m2!1sen!2s!4v1676730995998!5m2!1sen!2s"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            style={{ border: 0, borderRadius: '8px' }}
          ></iframe>
        </div>
        <div className={styles.infoContainer}>
          <h1 className={styles.title}>Let's discuss your project</h1>
          <p className={styles.text}>
            Give us a call today to discuss your project. Our knowledgeable staff would be happy to provide an estimate for your needs.
          </p>
          <p className={styles.info}>
            15810 114 Ave NW, Edmonton, AB T5M 2Z4
          </p>
          <p className={styles.info}>
            <strong>Email:</strong> <a href="mailto:hello@abcrailing.com">hello@abcrailing.com</a>
          </p>
          <p className={styles.info}>
            <strong>Phone:</strong> <a href="tel:+17804390801">+1 780 439 0801</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
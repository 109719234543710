import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './Terms.module.css';

const Terms = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Terms of Service - ABC Railing</title>
        <meta name="description" content="Read our terms of service and understand your rights." />
      </Helmet>
      <h1 className={styles.heading}>Terms of Service</h1>

      <section aria-labelledby="acceptance-of-terms">
        <h2 id="acceptance-of-terms" className={styles.subheading}>Acceptance of Terms</h2>
        <p>
          By accessing or using our services, you agree to comply with and be bound by these Terms of Service.
          If you do not agree to these terms, please do not use our services.
        </p>
      </section>

      <section aria-labelledby="changes-to-terms">
        <h2 id="changes-to-terms" className={styles.subheading}>Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on this page.
          Your continued use of our services after changes are posted constitutes your acceptance of the new terms.
        </p>
      </section>

      <section aria-labelledby="user-obligations">
        <h2 id="user-obligations" className={styles.subheading}>User Obligations</h2>
        <p>You agree to:</p>
        <ul className={styles.bulletPoints}>
          <li>Provide accurate and complete information when creating an account or using our services.</li>
          <li>Not engage in any unlawful activities while using our services.</li>
          <li>Not use our services to distribute spam or harmful content.</li>
        </ul>
      </section>

      <section aria-labelledby="limitation-of-liability">
        <h2 id="limitation-of-liability" className={styles.subheading}>Limitation of Liability</h2>
        <p>
          South Side Manufacturing Ltd. shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of our services,
          to the fullest extent permitted by law.
        </p>
      </section>

      <section aria-labelledby="governing-law">
        <h2 id="governing-law" className={styles.subheading}>Governing Law</h2>
        <p>
          These Terms of Service shall be governed by and construed in accordance with the laws of Alberta.
        </p>
      </section>

      <section aria-labelledby="contact-us">
        <h2 id="contact-us" className={styles.subheading}>Contact Us</h2>
        <p>If you have any questions regarding these Terms of Service, please contact us at <a href="mailto:hello@abcrailing.com">hello@abcrailing.com</a>.</p>
      </section>
    </div>
  );
};

export default Terms;
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.css';

import architecutralImage from '../assets/images/architectural-series.webp';
import builderImage from '../assets/images/builder-series.webp';
import commercialImage from '../assets/images/commercial-series.webp';

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const currentHost = window.location.host.replace(/^www\./, '');

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#series') {
      const seriesSection = document.getElementById('series');
      if (seriesSection) {
        seriesSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [window.location.hash]);

  const handleTouchStart = (index) => {
    setActiveIndex(index);
  };

  const handleTouchEnd = () => {
    setActiveIndex(null);
  };

  const imageData = [
    {
      href: "/architectural",
      src: architecutralImage,
      alt: "Architectural Series",
      title: "Architectural Series",
      description:
        "Experience the pinnacle of elegance with our Architectural Series. Crafted from premium aluminum and iron alloys, these powder-coated railings are perfect for both exterior and interior applications, redefining luxury and sophistication.",
    },
    {
      href: `https://bgrs.${currentHost}`,
      src: builderImage,
      alt: "Builder Series",
      title: "Builder Series",
      description:
        "Discover the perfect blend of affordability and style with our Builder Series. Made exclusively from aluminum alloys and available in textured black and gloss white powder coatings, these railings offer a premium look at a builder-friendly price.",
      target: "_blank",
      rel: "noopener noreferrer"
    },
    {
      href: "/commercial",
      src: commercialImage,
      alt: "Commercial Series",
      title: "Commercial Series",
      description:
        "Choose durability and compliance with our Commercial Series. Designed to meet stringent building codes without compromising on style, they offer reliability and longevity, making them the perfect choice for any commercial application.",
    },
  ];

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Home - ABC Railing</title>
        <meta name="description" content="Precision-engineered metal railings for architectural, residential, and commercial applications. Custom design meets superior craftsmanship." />
        <meta name="keywords" content="metal railings, stair railings, architectural railings, commercial railings, custom metal design" />
      </Helmet>

      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1>Premium Metal Railings & Stairs</h1>
          <p>Combining style, safety, and durability for every application.</p>
          <a href="#series">
            <span>Explore Our Products</span>
          </a>
        </div>
      </section>

      <section id="who-we-are" className={styles.whoWeAreSection}>
        <div className={styles.sectionContent}>
          <h2>Who We Are</h2>
          <p>ABC Railing is a leading manufacturer of premium metal railings, dedicated to delivering exceptional quality and innovative design. With years of expertise in metal fabrication, we pride ourselves on creating solutions that seamlessly blend aesthetic appeal with structural integrity.</p>
        </div>
      </section>

      <section id="series" className={styles.seriesSection}>
        <div className={styles.imageContainer}>
          {imageData.map((image, index) => (
            <div
              key={index}
              className={`${styles.imageWrapper} ${activeIndex === index ? styles.active : ''}`}
              onTouchStart={() => handleTouchStart(index)}
              onTouchEnd={handleTouchEnd}
            >
              <a
                href={image.href}
                target={image.target || '_self'}
                rel={image.rel || 'noopener noreferrer'}
                aria-label={image.title}
              >
                <img src={image.src} alt={image.alt} className={styles.image} />
                <div className={styles.imageOverlay}>
                  <h3 className={styles.imageTitle}>{image.title}</h3>
                  <p className={styles.imageDescription}>{image.description}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.missionSection}>
        <div className={styles.sectionContent}>
          <h2>Our Mission</h2>
          <p>We are committed to transforming spaces through meticulously crafted railings that elevate both form and function. Our mission is to provide innovative, high-quality metal railing solutions that enhance architectural beauty, ensure safety, and exceed customer expectations.</p>
        </div>

        <div className={styles.ctaSection}>
          <button
            onClick={() => navigate('/contact')}
            className={styles.ctaButton}
            aria-label="Contact us for a consultation"
          >
            Request Consultation Now
          </button>
        </div>

      </section>
    </div>
  );
};

export default Home;
const imageData = [
  {
    "id": 1,
    "thumbnailSrc": 'images/architectural/Interior Iron 1 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 1.webp',
    "alt": "Interior Iron Installation 1",
    "description": "Our best-selling vertical picket railing: timeless design, perfect for contemporary spaces.",
    "galleryType": "interior"
  },
  {
    "id": 2,
    "thumbnailSrc": 'images/architectural/Interior Iron 2 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 2.webp',
    "alt": "Interior Iron Installation 2",
    "description": "Minimalistic railing, perfect for sleek, modern spaces.",
    "galleryType": "interior"
  },
  {
    "id": 3,
    "thumbnailSrc": 'images/architectural/Interior Iron 3 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 3.webp',
    "alt": "Interior Iron Installation 3",
    "description": "Modern, sleek stair railing, no base plates needed.",
    "galleryType": "interior"
  },
  {
    "id": 4,
    "thumbnailSrc": 'images/architectural/Interior Iron 4 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 4.webp',
    "alt": "Interior Iron Installation 4",
    "description": "Contemporary railing with continuous floor mount.",
    "galleryType": "interior"
  },
  {
    "id": 5,
    "thumbnailSrc": 'images/architectural/Interior Iron 5 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 5.webp',
    "alt": "Interior Iron Installation 5",
    "description": "Clustered varying-width pickets: adding old-world charm to a modern home.",
    "galleryType": "interior"
  },
  {
    "id": 6,
    "thumbnailSrc": 'images/architectural/Interior Iron 6 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 6.webp',
    "alt": "Interior Iron Installation 6",
    "description": "Sleek vertical pickets with a flat bar top.",
    "galleryType": "interior"
  },
  {
    "id": 7,
    "thumbnailSrc": 'images/architectural/Interior Iron 7 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 7.webp',
    "alt": "Interior Iron Installation 7",
    "description": "Sturdy horizontal pickets: another great option.",
    "galleryType": "interior"
  },
  {
    "id": 8,
    "thumbnailSrc": 'images/architectural/Interior Iron 8 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 8.webp',
    "alt": "Interior Iron Installation 8",
    "description": "Minimalist vertical pickets with flat bar top and continuous floor mount.",
    "galleryType": "interior"
  },
  {
    "id": 9,
    "thumbnailSrc": 'images/architectural/Interior Iron 9 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 9.webp',
    "alt": "Interior Iron Installation 9",
    "description": "Elevate your space with this modern stair railing.",
    "galleryType": "interior"
  },
  {
    "id": 10,
    "thumbnailSrc": 'images/architectural/Interior Iron 10 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 10.webp',
    "alt": "Interior Iron Installation 10",
    "description": "Another set of sleek vertical picket railings.",
    "galleryType": "interior"
  },
  {
    "id": 11,
    "thumbnailSrc": 'images/architectural/Interior Iron 11 Thumbnail.webp',
    "src": 'images/architectural/Interior Iron 11.webp',
    "alt": "Interior Iron Installation 11",
    "description": "Ornamental iron railings bringing customer's ideas to life.",
    "galleryType": "interior"
  },
  {
    "id": 12,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 1 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 1.webp',
    "alt": "Exterior Aluminum Installation 1",
    "description": "Our best-selling exterior product: sleek vertical picket railings.",
    "galleryType": "exterior"
  },
  {
    "id": 13,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 2 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 2.webp',
    "alt": "Exterior Aluminum Installation 2",
    "description": "Modern horizontal picket railings add partial privacy and aesthetic appeal.",
    "galleryType": "exterior"
  },
  {
    "id": 14,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 3 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 3.webp',
    "alt": "Exterior Aluminum Installation 3",
    "description": "Modern horizontal picket railings add partial privacy and aesthetic appeal.",
    "galleryType": "exterior"
  },
  {
    "id": 15,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 4 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 4.webp',
    "alt": "Exterior Aluminum Installation 4",
    "description": "Modern horizontal picket railings add partial privacy and aesthetic appeal.",
    "galleryType": "exterior"
  },
  {
    "id": 16,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 5 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 5.webp',
    "alt": "Exterior Aluminum Installation 5",
    "description": "Modern horizontal picket railings add partial privacy and aesthetic appeal.",
    "galleryType": "exterior"
  },
  {
    "id": 17,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 6 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 6.webp',
    "alt": "Exterior Aluminum Installation 6",
    "description": "Modern horizontal picket railings add partial privacy and aesthetic appeal.",
    "galleryType": "exterior"
  },
  {
    "id": 18,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 7 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 7.webp',
    "alt": "Exterior Aluminum Installation 7",
    "description": "Contemporary horizontal picket railing beautifully adorning this patio.",
    "galleryType": "exterior"
  },
  {
    "id": 19,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 8 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 8.webp',
    "alt": "Exterior Aluminum Installation 8",
    "description": "Aluminum stringers with vinyl-wrapped treads and framed glass railings enhance the overall look.",
    "galleryType": "exterior"
  },
  {
    "id": 20,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 9 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 9.webp',
    "alt": "Exterior Aluminum Installation 9",
    "description": "Horizontal picket railings: a modern alternative to vertical pickets.",
    "galleryType": "exterior"
  },
  {
    "id": 21,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 10 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 10.webp',
    "alt": "Exterior Aluminum Installation 10",
    "description": "Tinted glass railings perfectly complement this Mediterranean-style house.",
    "galleryType": "exterior"
  },
  {
    "id": 22,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 11 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 11.webp',
    "alt": "Exterior Aluminum Installation 11",
    "description": "Horizontal pickets with a refined wooden top.",
    "galleryType": "exterior"
  },
  {
    "id": 23,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 12 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 12.webp',
    "alt": "Exterior Aluminum Installation 12",
    "description": "Topless glass railings add modern elegance to this rooftop patio.",
    "galleryType": "exterior"
  },
  {
    "id": 24,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 13 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 13.webp',
    "alt": "Exterior Aluminum Installation 13",
    "description": "Topless glass railings with smaller posts offer unobstructed views of nature.",
    "galleryType": "exterior"
  },
  {
    "id": 25,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 14 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 14.webp',
    "alt": "Exterior Aluminum Installation 14",
    "description": "Spigot-style topless glass railings adapt to decks of any shape.",
    "galleryType": "exterior"
  },
  {
    "id": 26,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 15 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 15.webp',
    "alt": "Exterior Aluminum Installation 15",
    "description": "Frameless glass railings complement a custom wood-style privacy divider.",
    "galleryType": "exterior"
  },
  {
    "id": 27,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 16 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 16.webp',
    "alt": "Exterior Aluminum Installation 16",
    "description": "Seamless integration of flat and stair railings.",
    "galleryType": "exterior"
  },
  {
    "id": 28,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 17 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 17.webp',
    "alt": "Exterior Aluminum Installation 17",
    "description": "Vertical picket railings with a refined wooden top.",
    "galleryType": "exterior"
  },
  {
    "id": 29,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 18 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 18.webp',
    "alt": "Exterior Aluminum Installation 18",
    "description": "Sleek transition points with termination posts over the rail from stair to flat railing.",
    "galleryType": "exterior"
  },
  {
    "id": 30,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 19 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 19.webp',
    "alt": "Exterior Aluminum Installation 19",
    "description": "Spiral staircases available in various configurations, with sturdy picket railings for added strength and stability.",
    "galleryType": "exterior"
  },
  {
    "id": 31,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 20 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 20.webp',
    "alt": "Exterior Aluminum Installation 20",
    "description": "Elegant framed glass railings on a stunning infill house.",
    "galleryType": "exterior"
  },
  {
    "id": 32,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 21 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 21.webp',
    "alt": "Exterior Aluminum Installation 21",
    "description": "Our glass railings enhance the curb appeal of this home.",
    "galleryType": "exterior"
  },
  {
    "id": 33,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 22 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 22.webp',
    "alt": "Exterior Aluminum Installation 22",
    "description": "Our railings during a beautiful sunset.",
    "galleryType": "exterior"
  },
  {
    "id": 34,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 23 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 23.webp',
    "alt": "Exterior Aluminum Installation 23",
    "description": "Vertical picket railings in white.",
    "galleryType": "exterior"
  },
  {
    "id": 35,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 24 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 24.webp',
    "alt": "Exterior Aluminum Installation 24",
    "description": "Vertical picket railings in white.",
    "galleryType": "exterior"
  },
  {
    "id": 36,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 25 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 25.webp',
    "alt": "Exterior Aluminum Installation 25",
    "description": "Vertical picket railings in white on a front porch.",
    "galleryType": "exterior"
  },
  {
    "id": 37,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 26 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 26.webp',
    "alt": "Exterior Aluminum Installation 26",
    "description": "Vertical picket railings in white on a front porch.",
    "galleryType": "exterior"
  },
  {
    "id": 38,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 27 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 27.webp',
    "alt": "Exterior Aluminum Installation 27",
    "description": "Vertical picket railings complement our frosted glass privacy divider.",
    "galleryType": "exterior"
  },
  {
    "id": 39,
    "thumbnailSrc": 'images/architectural/Exterior Aluminum 28 Thumbnail.webp',
    "src": 'images/architectural/Exterior Aluminum 28.webp',
    "alt": "Exterior Aluminum Installation 28",
    "description": "Safe and sturdy picket guardrails.",
    "galleryType": "exterior"
  },
  {
    "id": 40,
    "thumbnailSrc": 'images/commercial/Pipe Rail 1 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 1.webp',
    "alt": "Exterior Pipe Rail Installation 1",
    "description": "Durable exterior iron pipe railing.",
    "galleryType": "commercial"
  },
  {
    "id": 41,
    "thumbnailSrc": 'images/commercial/Pipe Rail 2 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 2.webp',
    "alt": "Exterior Pipe Rail Installation 2",
    "description": "Pipe railing elegantly following curved stairs.",
    "galleryType": "commercial"
  },
  {
    "id": 42,
    "thumbnailSrc": 'images/commercial/Pipe Rail 3 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 3.webp',
    "alt": "Exterior Pipe Rail Installation 3",
    "description": "Sturdy pipe handrails.",
    "galleryType": "commercial"
  },
  {
    "id": 43,
    "thumbnailSrc": 'images/commercial/Pipe Rail 4 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 4.webp',
    "alt": "Exterior Pipe Rail Installation 4",
    "description": "Pipe railing enhanced with vertical pickets.",
    "galleryType": "commercial"
  },
  {
    "id": 44,
    "thumbnailSrc": 'images/commercial/Pipe Rail 5 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 5.webp',
    "alt": "Interior Pipe Rail Installation 5",
    "description": "Iron railing for multi-storey stairwells.",
    "galleryType": "commercial"
  },
  {
    "id": 45,
    "thumbnailSrc": 'images/commercial/Pipe Rail 6 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 6.webp',
    "alt": "Interior Pipe Rail Installation 6",
    "description": "Vertical picket railing for stairwells.",
    "galleryType": "commercial"
  },
  {
    "id": 46,
    "thumbnailSrc": 'images/commercial/Pipe Rail 7 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 7.webp',
    "alt": "Interior Pipe Rail Installation 7",
    "description": "Continuous wall-mounted handrails and stair picket guard railing.",
    "galleryType": "commercial"
  },
  {
    "id": 47,
    "thumbnailSrc": 'images/commercial/Pipe Rail 8 Thumbnail.webp',
    "src": 'images/commercial/Pipe Rail 8.webp',
    "alt": "Exterior Pipe Rail Installation 8",
    "description": "Surface-mounted exterior handrail.",
    "galleryType": "commercial"
  },
  {
    "id": 48,
    "thumbnailSrc": 'images/commercial/Fencing 1 Thumbnail.webp',
    "src": 'images/commercial/Fencing 1.webp',
    "alt": "Fencing Installation 1",
    "description": "Aluminum fencing for row houses.",
    "galleryType": "commercial"
  },
  {
    "id": 49,
    "thumbnailSrc": 'images/commercial/Fencing 2 Thumbnail.webp',
    "src": 'images/commercial/Fencing 2.webp',
    "alt": "Fencing Installation 2",
    "description": "Aluminum fencing - 1",
    "galleryType": "commercial"
  },
  {
    "id": 50,
    "thumbnailSrc": 'images/commercial/Fencing 3 Thumbnail.webp',
    "src": 'images/commercial/Fencing 3.webp',
    "alt": "Fencing Installation 3",
    "description": "Aluminum fencing - 2",
    "galleryType": "commercial"
  },
  {
    "id": 51,
    "thumbnailSrc": 'images/commercial/Fencing 4 Thumbnail.webp',
    "src": 'images/commercial/Fencing 4.webp',
    "alt": "Fencing Installation 4",
    "description": "Aluminum fencing - 3",
    "galleryType": "commercial"
  }
];

export default imageData;